import "./styles.css";
import Header from "./header.js";
import Main from "./main.js";

export default function App() {
  return (
    <div className="App">
      <Header />
      <Main />
    </div>
  );
}
