export default () => {
  return (
    <main>
      <h2>My name is Ben Aston.</h2>
      <p>
        My interests are: the craft of developing software, the Web, industrial
        design, and political science.
      </p>
      <p>
        The commercial output of my product design work can be found on{" "}
        <a href="https://www.aerohead.cc">aerohead.cc</a>.
      </p>
      <p>I can be contacted on b@bjma.mx.</p>
    </main>
  );
};
